import React from "react"
import styled from "styled-components"

const ToolbarContainer = styled.div`
  width: 100;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -1.45rem;
  margin-bottom: 1.45rem;
  padding: 0 1.0875rem;
  border-bottom: 1px solid #dfe1e5;
  > * {
    border: 1px solid #dfe1e5;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: white;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 10px;
    }
    > * {
      width: 100%;
      text-align: center;
    }
  }
`

const Toolbar = ({ onClick, view }) => {
  return (
    <ToolbarContainer>
      {/* eslint-disable-next-line */}
      <button onClick={() => onClick("Aide")}>
        <span>?</span>
      </button>
      {/* eslint-disable-next-line */}
      {view === "Arbre" && (
        <button onClick={() => onClick("Accueil")}>
          <span>&#x1f50d;</span>
        </button>
      )}
      {view === "Accueil" && (
        <button
          type="button"
          title="Ascendant"
          onClick={() => onClick("Arbre")}
        >
          <div>
            <svg width="22px" height="22px" viewBox="0 0 38 30">
              <g stroke="none" strokeWidth="1" fill="none">
                <g fill="rgba(30,30,30,0.4)">
                  <path d="M37,21.523 L35.002,21.523 L35.002,15.607 C35.002,15.193 34.666,14.857 34.252,14.857 L19.75,14.857 L19.75,9.693 L22.357,9.693 C22.907,9.693 23.357,9.244 23.357,8.693 L23.357,1.977 C23.357,1.428 22.907,0.977 22.357,0.977 L15.641,0.977 C15.091,0.977 14.641,1.428 14.641,1.977 L14.641,8.694 C14.641,9.245 15.091,9.694 15.641,9.694 L18.249,9.694 L18.249,14.858 L3.749,14.858 C3.335,14.858 2.999,15.194 2.999,15.608 L2.999,21.524 L1,21.524 C0.45,21.524 0,21.973 0,22.524 L0,28.024 C0,28.573 0.45,29.024 1,29.024 L6.499,29.024 C7.049,29.024 7.499,28.573 7.499,28.024 L7.499,22.524 C7.499,21.973 7.049,21.524 6.499,21.524 L4.499,21.524 L4.499,16.358 L18.25,16.358 L18.25,21.524 L16.25,21.524 C15.7,21.524 15.25,21.973 15.25,22.524 L15.25,28.024 C15.25,28.573 15.7,29.024 16.25,29.024 L21.749,29.024 C22.299,29.024 22.749,28.573 22.749,28.024 L22.749,22.524 C22.749,21.973 22.299,21.524 21.749,21.524 L19.75,21.524 L19.75,16.358 L33.502,16.358 L33.502,21.524 L31.501,21.524 C30.951,21.524 30.501,21.973 30.501,22.524 L30.501,28.024 C30.501,28.573 30.951,29.024 31.501,29.024 L37,29.024 C37.55,29.024 38,28.573 38,28.024 L38,22.524 C38,21.973 37.55,21.523 37,21.523 Z"></path>
                </g>
              </g>
            </svg>
          </div>
        </button>
      )}
      {/* eslint-disable-next-line */}
      <a
        href={`mailto:jmambroselli@icloud.com,arnaud.ambroselli@icloud.com?subject=À%20propos%20de%20la%20généalogie%20Missoffe%20sur%20missoffes.com&body=Veuillez%20préciser%20vos%20coordonnées%20pour%20pouvoir%20vous%20répondre,%20merci%20!`}
      >
        <span>&#128140;</span>
      </a>
    </ToolbarContainer>
  )
}

export default Toolbar
