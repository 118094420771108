import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'

const HeaderStyled = styled.header`
  background: rgba(181, 57, 65, 1.00);
  margin-bottom: 1.45rem;
  > div {
    margin: 0 auto;
    max-width: 960px;
    padding: 1.45rem 1.0875rem;
    > h1 {
      margin: 0;
      > a {
        color: white;
        text-decoration: none;
      }
    }
  }
`

const Header = ({ siteTitle }) => (
  <HeaderStyled>
    <div>
      <h1>
        <Link to="/">
          {siteTitle}
        </Link>
      </h1>
    </div>
  </HeaderStyled>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
